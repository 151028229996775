import http from './httpService';
import config from '../config.json';

export function getDonations() {
    return http.get(config.apiEndpointRemote + "donations.php");
    //return http.get(config.apiEndpoint + "donations.php");
}

export function insertDonationWithoutImage(data) {
    return http.post(config.apiEndpointRemote + "donations.php", data);
    //return http.get(config.apiEndpointRemote + "donations.php");
}

export function getDonationsById(id) {
    return http.get(config.apiEndpointRemote + "donations.php/" + id);
    //return http.get(config.apiEndpoint + "donations.php/" + id);
}

export function insertDonationWithImage(data) {
    return http.post(config.apiEndpointRemote + "donations.php", data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    //return http.get(config.apiEndpointRemote + "donations.php");
}

