import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Pagination,
  Stack,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import React from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

//Da aggiungere controllo se non ci sono dati
//Aggiungere modifica delle icone in base alla pagina
const CustomList = ({
  data,
  page,
  rowsPerPage,
  onPageChange,
  onAddItem,
  onEditItem,
  onDeleteItem,
  numberOfPages,
  checked,
  onToggle,
}) => {
  return (
    <div>
      <Box style={{ textAlign: "right" }}>
        <IconButton
          aria-label="delete"
          size="large"
          color="success"
          onClick={onAddItem}
        >
          <AddCircleIcon fontSize="inherit" />
        </IconButton>
        {checked.length > 0 ? (
          <IconButton
            aria-label="delete"
            size="large"
            color="error"
            onClick={onDeleteItem}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        ) : (
          <></>
        )}
        {checked.length === 1 ? (
          <IconButton
            aria-label="delete"
            size="large"
            color="success"
            onClick={onEditItem}
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        ) : (
          <></>
        )}
      </Box>
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        style={{ margin: "auto" }}
      >
        {data
          .slice(
            (page - 1) * rowsPerPage,
            (page - 1) * rowsPerPage + rowsPerPage
          )
          .map((item) => (
            <ListItem
              key={item.id}
              secondaryAction={
                <Checkbox
                  edge="end"
                  onChange={onToggle(item.id)}
                  checked={checked.indexOf(item.id) !== -1}
                />
              }
              disablePadding
            >
              <ListItemAvatar>
                <Avatar>
                  <AccountCircle />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={item.primary} secondary={item.secondary} />
            </ListItem>
          ))}
      </List>
      <Stack spacing={2}>
        <Pagination
          count={numberOfPages}
          page={page}
          color="primary"
          style={{ margin: "auto" }}
          size="large"
          onChange={onPageChange}
        />
      </Stack>
    </div>
  );
};

export default CustomList;
