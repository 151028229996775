import { Button, Card, CardMedia, Grid } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ClearIcon from '@mui/icons-material/Clear';
import React from "react";

const DonationsImage = ({preview, onChangeHandler, onRemoveImage}) => {
  return (
    <>
      <h3>Aggiungi la foto della donazione</h3>
      {preview ? (
        <Card
          sx={{ maxWidth: 345 }}
          style={{ margin: "auto", marginTop: "5%" }}
        >
          <CardMedia
            component="img"
            height="200"
            style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "contain" }}
            image={preview}
          />
        </Card>
      ) : (
        <h1>No Image</h1>
      )}
      <Grid container spacing={2} style={{ marginTop: "2%" }}>
        <Grid item xs={6}>
          <label htmlFor="btn-upload">
            <input
              id="btn-upload"
              name="btn-upload"
              style={{ display: "none" }}
              type="file"
              onChange={onChangeHandler}
            />
            <Button
              className="btn-choose"
              variant="outlined"
              component="span"
              startIcon={<FileUploadIcon />}
            >
              Scegli file
            </Button>
          </label>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
          <Button
            className="btn-choose"
            color="error"
            variant="outlined"
            component="span"
            startIcon={<ClearIcon />}
            onClick={onRemoveImage}
          >
            Rimuovi file
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DonationsImage;
