import { Box, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDonations } from "../services/donationsService";
import CustomDialog from "./utils/CustomDialog";
import CustomTable from "./utils/CustomTable";
import ExportExcel from "./utils/exportExcel";
import OptionsTable from "./utils/OptionsTable";
import { filterSelect, select, SelectAllClick } from "./utils/selecting";

const columns = [
  "Name",
  "Surname",
  "Description",
  "Donation_Date",
  "Img_path",
  "Category",
];

const DonationsTable = () => {
  const navigate = useNavigate();
  const [donationsData, setDonationsData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [selected, setSelected] = React.useState([]);
  const [filteredDonations, setFilteredDonations] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(columns[0].toLowerCase());
  const [openDialog, setOpenDialog] = useState(false);
  const [filter, setFilter] = useState({
    selected: "",
    text: "",
  });

  useEffect(() => {
    const getDonationsData = async () => {
      let { data } = await getDonations();
      if (data) {
        const donations = data.data;
        setDonationsData(donations);
        setFilteredDonations(donations);
      }
    };
    getDonationsData();
  }, []);

  const handleSelectAllClick = (event) => {
    const data = SelectAllClick(event, filteredDonations);
    setSelected(data);
  };

  const handleClick = (event, id) => {
    const newSelected = select(event, id, selected);
    setSelected(newSelected);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleFilterSelect = (target) => {
    const { newFilter, newFilteredData } = filterSelect(
      target,
      filter,
      donationsData
    );
    setFilter(newFilter);
    setFilteredDonations(newFilteredData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Da aggiungere chiamata all'api
  const handleClose = (status) => {
    if (status) {
      handleDeleteItem();
    }
    setOpenDialog(false);
  };

  const handleDeleteItem = () => {
    const newDonationsData = donationsData.filter(
      (don) => !selected.includes(don.id)
    );
    setFilteredDonations(newDonationsData);
    setSelected([]);
  };

  return (
    <div
      style={{
        width: "80%",
        textAlign: "center",
        margin: "auto",
        marginTop: "5%",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <OptionsTable
          columns={columns}
          filter={filter}
          onChangeSelect={handleFilterSelect}
          disabledButton={selected}
          deleteButton={() => setOpenDialog(true)}
          onAddItem={() => navigate("/new-donations")}
          onEditItem={() => navigate(`/users/${selected[0]}`)}
        />
        <CustomTable
          onSelectAllClick={handleSelectAllClick}
          onSelectClick={handleClick}
          columns={columns}
          order={order}
          orderBy={orderBy}
          data={filteredDonations}
          selected={selected}
          onRequestSort={handleRequestSort}
          rowsPerPage={rowsPerPage}
          page={page}
        />
        <TablePagination
          rowsPerPageOptions={[2, 4, 6]}
          component="div"
          count={filteredDonations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <ExportExcel
        data={filteredDonations}
        fileName="donations.xlsx"
      />
      <CustomDialog
        title={"Attenzione"}
        text={"Confermi di eliminare l' utente?"}
        open={openDialog}
        onClose={handleClose}
      />
    </div>
  );
};

export default DonationsTable;
