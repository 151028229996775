import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import React from "react";

const DonationDonator = ({
  onChange,
  donation,
  categories,
  errors,
  onChangeStep,
  plants,
  checked,
  onChecked,
  onClick
}) => {
  const { name, surname, description, donation_date, id_category, id_plants } =
    donation;
  return (
    <>
      <Grid container spacing={2} style={{ marginTop: "2%" }}>
        <Grid item xs={6}>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={checked} onChange={onChecked} />}
              label="Donatore già esistente"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {checked ? (
          <Grid item xs={6} style={{ display: "flex" }}>
            <TextField
              fullWidth
              margin="normal"
              value={id_category}
              label="Donatori esistenti"
              select
              error={errors.id_category ? true : false}
              helperText={errors.id_category}
              name="id_donator"
              onChange={onChange}
            >
              {categories.map((c) => {
                return (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Nome del donatore"
                name="name"
                value={name}
                onChange={onChange}
                error={errors.name ? true : false}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="surname"
                value={surname}
                label="Cognome del donatore"
                onChange={onChange}
                error={errors.surname ? true : false}
                helperText={errors.surname}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Descrizione"
            name="description"
            value={description}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Data di donazione"
            type="date"
            name="donation_date"
            value={donation_date}
            onChange={onChange}
            error={errors.donation_date ? true : false}
            helperText={errors.donation_date}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ display: "flex" }}>
          <TextField
            fullWidth
            margin="normal"
            value={id_category}
            label="Tipo di donazione"
            select
            error={errors.id_category ? true : false}
            helperText={errors.id_category}
            name="id_category"
            onChange={onChange}
          >
            {categories.map((c) => {
              return (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        {categories.find(c => (c.id === id_category && c.name !== "Alberi"))|| id_category === "" ? (
          <Grid item xs={6} style={{ display: "flex" }}>
            <Button
              margin="normal"
              variant="contained"
              color="success"
              style={{ margin: "auto" }}
              size="large"
              onClick={onClick}
            >
              Avanti
            </Button>
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Data di piantagione"
                type="date"
                name="donation_date"
                value={donation_date}
                onChange={onChange}
                error={errors.donation_date ? true : false}
                helperText={errors.donation_date}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6} style={{ display: "flex" }}>
              <TextField
                margin="normal"
                fullWidth
                value={id_plants}
                label="Tipo di Pianta"
                select
                error={errors.id_plants ? true : false}
                helperText={errors.id_plants}
                name="id_plants"
                onChange={onChange}
              >
                {plants.map((p) => {
                  return (
                    <MenuItem key={p.id} value={p.id}>
                      {p.type}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={6} style={{ display: "flex" }}>
              <Button
                margin="normal"
                variant="contained"
                color="success"
                style={{ margin: "auto" }}
                size="large"
                onClick={onChangeStep}
              >
                Avanti
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default DonationDonator;
