import React, { useEffect } from 'react';
import { useState } from 'react';
import { getPlantsById } from '../services/plantsService';

import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "./misc/Headings";


const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row  justify-between space-x-1  max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 lg:w-5/12 flex-shrink-0 h-80 md:w-auto `;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-full`,
]);

const TextContent = tw.div`lg:py-8`;
const Heading = tw(SectionHeading)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-blue-800 mt-4`
const Statistics = tw.div`mt-6 lg:mt-8 xl:mt-16 flex flex-wrap`
const Statistic = tw.div`text-lg sm:text-2xl lg:text-3xl w-1/2 mt-4 lg:mt-10 text-center md:text-left`
const Value = tw.div`font-bold text-blue-800`
const Key = tw.div`font-medium text-black`

const SearchTree = () => {
  const [tree, setTree] = useState({})


  useEffect(() => {

    const [path, id] = window.location.pathname.substring(1).split("/");
    const getPlantsData = async () => {
      let { data } = await getPlantsById(id);
      if (data) {
        console.log(data);
        setTree(data.data[0]);
      }
    };
    getPlantsData();
  }, []);

  const path = 'https://donationgest.parrocchiesanpieroasieve.it/donation_img/' + tree.img_path;

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={path} />
        </ImageColumn>
        <TextColumn textOnLeft={false}>
          <TextContent>
            <Heading>Famiglia Bonechi</Heading>
            <Description>{tree.description}</Description>
            <Description>{tree.description}</Description>
            <Statistics>
            <Statistic >
                <Value>{tree.planting_date}</Value>
                <Key>Piantumazione</Key>
              </Statistic>
              <Statistic >
                <Value>{tree.type}</Value>
                <Key>Tipo di pianta</Key>
              </Statistic>
              <Statistic >
                <Value>{tree.donation_date}</Value>
                <Key>Data donazione</Key>
              </Statistic>
              
            </Statistics>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
}
export default SearchTree;