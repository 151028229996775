import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import swal from "sweetalert";
import axios from "axios";
import { validate } from "validate.js";

const theme = createTheme();

const loginUser = async (credentials) => {
  //return await axios.post('https://donationgest.parrocchiesanpieroasieve.it/api/login-jwt.php', credentials);
  return await axios.post(
    "https://donationgest.parrocchiesanpieroasieve.it//api/login-jwt.php",
    credentials
  );
};

const Signin = () => {
  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  const [progress, setProgress] = useState(false);
  const [errors, setErrors] = useState({ username: "", password: "" });

  const constraints = {
    username: {
      presence: { allowEmpty: false },
    },
    password: {
      presence: { allowEmpty: false },
    },
  };

  //TODO refactoring di questa funzione con una validate a parte
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newError = { ...errors };
    validate.validators.presence.message = " non deve essere vuoto";
    const errorMessage = validate(user, constraints);
    console.log(errorMessage);
    if (errorMessage) {
      if (errorMessage.username) newError.username = errorMessage.username[0];
      if (errorMessage.password) newError.password = errorMessage.password[0];
      setErrors(newError);
    } else {
      const response = await loginUser(user);
      const { status, jwt, message } = response.data;
      if (status) {
        swal("Accesso eseguito", "", "success", {
          buttons: false,
          timer: 2000,
        }).then(() => {
          localStorage.setItem("jwt", jwt);
          window.location.href = "/users";
        });
      } else {
        swal("Errore", message, "error");
        setProgress(false);
      }
    }
  };

  const handleChange = ({ target: { value, name } }) => {
    const newUser = { ...user };
    newUser[name] = value;
    setUser(newUser);
    const newError = { ...errors };
    validate.validators.presence.message = "Questo campo non deve essere vuoto";
    const errorMessage = validate.single(value, constraints[name]);
    if (errorMessage) {
      newError[name] = errorMessage[0];
      setErrors(newError);
    } else {
      newError[name] = "";
      setErrors(newError);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="email"
              autoFocus
              //onChange={e => setUserName(e.target.value)}
              onChange={handleChange}
              error={errors.username ? true : false}
              helperText={errors.username}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChange}
              error={errors.password ? true : false}
              helperText={errors.password}
            />
            <Button
              type="submit"
              disabled={errors.username || errors.password ? true : false}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        {progress ? (
          <Box sx={{ width: "100&" }}>
            <LinearProgress />
          </Box>
        ) : (
          <p></p>
        )}
      </Container>
    </ThemeProvider>
  );
};
export default Signin;
