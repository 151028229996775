import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { change, submit } from "./utils/form";

import {
  editPlantsById,
  getPlantsById,
  addPlant,
} from "../services/plantsService";
import ParkIcon from "@mui/icons-material/Park";
import { getDonators } from "../services/donatorService";
import { getDonations } from "../services/donationsService";

//TODO handleChange e handleSubmit da mettere in un file a parte e crearle generiche
const TreeForm = () => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [path, setPath] = useState({
    id: "",
    path: "",
  });
  const [errors, setErrors] = useState({
    type: "",
    planting_date: "",
    id_tree: "",
    id_donation: "",
    id_donator: "",
  });
  const [plant, setPlant] = useState({
    type: "",
    planting_date: "",
    id_tree: "",
    id_donation: "",
    id_donator: '',
  });

  const [donators, setDonators] = useState([]);
  const [donations, setDonations] = useState([]);

  useEffect(() => {
    const [path, id] = window.location.pathname.substring(1).split("/");
    setPath({ id: id, path: path });
    if (path === "tree") {
      const getTreeData = async () => {
        const { data } = await getPlantsById(id);
        setPlant(data.data[0]);
      };
      getTreeData();
    }
    const getDonator = async () => {
      const {
        data: { data },
      } = await getDonators();
      console.log(data);
      setDonators(data);
    };
    const getDonation = async () => {
      const {
        data: { data },
      } = await getDonations();
      console.log(data);
      setDonations(data);
    };

    getDonator();
    getDonation();

  }, []);

  const constraints = {
    type: {
      presence: { allowEmpty: false },
    },
    planting_date: {
      presence: { allowEmpty: false },
    },
    id_donator: {
      presence: { allowEmpty: false },
    },
    id_donation: {
      presence: { allowEmpty: false },
    },
  };

  const handleChange = ({ target: { value, name } }) => {
    const { changedItem, changedError } = change(
      { value, name },
      plant,
      errors,
      constraints
    );
    setErrors(changedError);
    setPlant(changedItem);
    setDisabled(isDisabled(changedError));
  };

  const isDisabled = (error) => {
    if (!error) return;
    const { type, planting_date, id_tree } = error;
    return type || planting_date || id_tree ? true : false;
  };

  const handleSubmit = async (e) => {
    const result = submit(e, plant, errors, constraints);
    setDisabled(isDisabled(result));
    if (result) {
      setErrors(result); //Quando si svilupperà submit() result avrà anche altri parametri
      return;
    }
    //Si rimuove id_donator per utilizzo dell addPlant a donazione già esistente
    let data;
    const newPlant = { ...plant };
    delete newPlant.id_donator;
    if (path.path === "tree") data = await editPlantsById(path.id, newPlant);
    else if (path.path === "new-tree") data = await addPlant(newPlant);
    if (data.status) navigate("/trees");
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ width: 56, height: 56, m: 1 }}>
          <ParkIcon fontSize="large" />
        </Avatar>
        <Typography component="h1" variant="h5"></Typography>
        <Box
          component="form"
          //onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Type"
                name="type"
                value={plant.type}
                onChange={handleChange}
                error={errors.type ? true : false}
                helperText={errors.type}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Id Tree"
                name="id_tree"
                value={plant.id_tree}
                onChange={handleChange}
                error={errors.id_tree ? true : false}
                helperText={errors.id_tree}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="planting_date"
                value={plant.planting_date}
                label="Planting date"
                onChange={handleChange}
                error={errors.planting_date ? true : false}
                helperText={errors.planting_date}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                name="id_donator"
                label="Donatore"
                value={plant.id_donator}
                select
                required
                fullWidth
                onChange={handleChange}
              >
                {donators.map((item) => {
                  const name = item.name + " " + item.surname;
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                name="id_donation"
                label="Donazione"
                value={plant.id_donation}
                select
                required
                fullWidth
                disabled={plant.id_donator ? false : true}
                onChange={handleChange}
              >
                {
                  donations.filter(item => item.id_donator === plant.id_donator).map((item) => {

                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.donation_date}
                      </MenuItem>
                    );
                  })}
              </TextField>
            </Grid>

            <div style={{ margin: "auto" }}>
              <Button
                type="submit"
                disabled={disabled}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit}
              >
                {window.location.pathname.substring(1).split("/")[0] === "tree"
                  ? "Modifica"
                  : "Aggiungi"}
              </Button>
            </div>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default TreeForm;
