const SET_STEP = "SET_STEP";
const STEP_ON = "STEP_ON";
const HANDLE_CHANGE = "HANDLE_CHANGE";
const SET_CATEGORIES = "SET_CATEGORIES";
const SET_PLANTS = "SET_PLANTS";
const SET_PREVIEW = "SET_PREVIEW";
const CHANGE_FILE = "CHANGE_FILE";
const REMOVE_IMAGE = "REMOVE_IMAGE";
const STEP_BACK = "STEP_BACK";
const SET_ERRORS = "SET_ERRORS";
const IS_CHECKED = "IS_CHECKED";

export {
  SET_STEP,
  STEP_ON,
  HANDLE_CHANGE,
  SET_CATEGORIES,
  SET_PLANTS,
  SET_PREVIEW,
  CHANGE_FILE,
  REMOVE_IMAGE,
  STEP_BACK,
  SET_ERRORS,
  IS_CHECKED
};
