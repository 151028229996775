import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircle from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

const OptionsTable = ({
  columns,
  filter,
  onChangeSelect,
  disabledButton,
  deleteButton,
  onAddItem,
  onEditItem,

}) => {
  const { selected, text } = filter;
  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Filtra per </InputLabel>
          <Select
            id="filterSelect"
            value={selected}
            label="Category"
            onChange={(event) => onChangeSelect(event)}
            name="selected"
          >
            {columns.map((col) => {
              return (
                <MenuItem value={col.toLowerCase()} key={col.toLowerCase()}>
                  {col}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        {selected ? (
          <FormControl variant="outlined">
            <InputLabel>Filtro per colonna</InputLabel>
            <OutlinedInput
              type="text"
              value={text}
              onChange={(event) => onChangeSelect(event)}
              label="Search text"
              name="text"
            />
          </FormControl>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={8} style={{ textAlign: "right" }}>
        <IconButton
          aria-label="add"
          size="large"
          color="success"
          onClick={onAddItem}
        >
          <AddCircle fontSize="inherit" />
        </IconButton>
        {disabledButton.length > 0 ? (
          <IconButton
            aria-label="delete"
            size="large"
            color="error"
            onClick={deleteButton}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        ) : (
          <></>
        )}
        {disabledButton.length === 1 ? (
          <IconButton aria-label="edit" size="large" color="success" onClick={onEditItem}>
            <EditIcon fontSize="inherit" />
          </IconButton>
        ) : (
          <></>
        )}

      </Grid>
    </Grid>
  );
};

export default OptionsTable;
