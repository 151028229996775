import http from './httpService';
import config from '../config.json';

export function getUsers() {
    return http.get(config.apiEndpointRemote + "test.php");
    //return http.get(config.apiEndpoint + "test.php");
}

export function getUserById(id) {
    return http.get(config.apiEndpointRemote + "test.php/" + id);
    //return http.get(config.apiEndpoint + "test.php/" + id);
}

export function editUserById(id, data) {
    return http.put(config.apiEndpointRemote + "test.php/" + id, data);
    //return http.put(config.apiEndpoint + "test.php/" + id, data);
}

export function addUser(data) {
    return http.post(config.apiEndpointRemote + "test.php", data);
    //return http.post(config.apiEndpoint + "test.php", data);
}

export function deleteUserById(id) {
    return http.delete(config.apiEndpointRemote + "test.php" + id);
    //return http.delete(config.apiEndpoint + "test.php" + id);
}