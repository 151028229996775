export const SelectAllClick = (event, filteredDonations) => {
  if (event.target.checked) {
    const newSelecteds = filteredDonations.map((n) => n.id);
    return newSelecteds;
  }
  return [];
};

export const select = (event, id, selected) => {
  const selectedIndex = selected.indexOf(id);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, id);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }
  return newSelected;
};

export const filterSelect = (
  { target: { value, name } },
  filter,
  tableData
) => {
  let data = {
    newFilteredData: [...tableData],
    newFilter: {},
  };
  const newFilter = { ...filter };
  newFilter[name] = value;
  if (name === "text") {
    if (filter.text !== value) {
      const newTableData = tableData.filter((d) =>
        d[filter.selected].toLowerCase().includes(value.toLowerCase())
          ? true
          : false
      );
      data.newFilteredData = [...newTableData];
    } else {
      console.log("test");
      data.newFilteredData = [...tableData];
    }
  }
  data.newFilter = { ...newFilter };
  return data;
};
