import { Button } from "@mui/material";
import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const ExportExcel = ({ data, fileName }) => {
  const fileExtension = ".xlsx";

  const exportData = (data, fileName) => {
    const col = Object.keys(data[0]).map(c => c.toUpperCase());
    const worksheet = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(worksheet, [col]);
    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: "A2",
      skipHeader: true,
    });
    const wb = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, {
      type: "array",
      cellStyles: true,
    });
    const finalData = new Blob([excelBuffer], { type: fileExtension });
    saveAs(finalData, fileName);
    console.log(worksheet);
  };
  return (
    <>
      <Button
        style={{ textAlign: "right" }}
        color="success"
        variant="contained"
        onClick={(e) => exportData(data, fileName)}
      >
        Export XLSX
      </Button>
    </>
  );
};

export default ExportExcel;
