import http from './httpService';
import config from '../config.json';

export function getPlants() {
    return http.get(config.apiEndpointRemote + "plants.php");
    //return http.get(config.apiEndpoint + "plants.php");
}
export function addPlant(data) {
    return http.post(config.apiEndpointRemote + "plants.php", data);
    //return http.get(config.apiEndpoint + "plants.php");
}
export function getPlantsById(id) {
    return http.get(config.apiEndpointRemote + "plants.php/" + id);
}
export function editPlantsById(id, data) {
    return http.put(config.apiEndpointRemote + "plants.php/" + id, data);
}

export function importPlantsWithIdDonation(data) {
    return http.post(config.apiEndpointRemote + "plants.php", data);
}

export function deletePlantsById(id) {
    return http.delete(config.apiEndpointRemote + "plants.php/" + id);
}