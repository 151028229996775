import "./App.css";
import Signin from "./components/Signin";
import UsersTable from "./components/usersTable";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MenuAppBar from "./components/MenuAppBar";
import { useEffect, useState } from "react";
import UsersForm from "./components/UsersForm";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JwtDecode from "jwt-decode";
import DonationsTable from "./components/donationsTable";
import DonationsForm from "./components/donationsForm";
import SearchTree from "./components/searchTree";
import PublicHeader from "./components/publicHeader";
import TreeTable from "./components/treeTable";
import TreeForm from "./components/TreeForm";
import PlantsList from "./components/mobile/plantsList";
import UsersList from "./components/mobile/usersList";

function App() {
  const [user, setUser] = useState();
  const token = localStorage.getItem("jwt");
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  useEffect(() => {
    if (token) {
      const { data } = JwtDecode(token);
      setUser(data);
    }
    const currentPath = window.location.pathname;
    if (currentPath === "/login" && token) {
      window.location.href = "/users";
    }
  }, []);

  //La condizione di user a true è necessaria perchè la setUser() è una
  //funzione asincrona. Questo significa che menuappbar viene renderizzato prima
  //che user sia settato. (In questo modo non c'è problema) forse
  return (
    <>
      {token !== "" &&
      user &&
      window.location.pathname.substring(1).split("/")[0] !== "searchTree" ? (
        <MenuAppBar user={user} />
      ) : (
        ""
      )}
      {window.location.pathname.substring(1).split("/")[0] === "searchTree" ? (
        <PublicHeader />
      ) : (
        ""
      )}

      <div className="wrapper">
        <ToastContainer />
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Signin />} />
            <Route path="/new-user" element={<UsersForm />} />
            <Route path="/users/:id" element={<UsersForm />} />
            <Route path="/donations" element={<DonationsTable />} />
            <Route path="/new-donations" element={<DonationsForm />} />
            <Route path="/trees" element={<TreeTable />} />
            <Route path="/" element={<UsersTable />} />
            <Route path="/searchTree/:id" element={<SearchTree />} />
            <Route path="/tree/:id" element={<TreeForm />} />

            <Route path="/treelist" element={<PlantsList />} />
            <Route path="/new-tree" element={<TreeForm />} />
            <Route
              path="/users"
              element={
                screenSize.dynamicWidth >= 800 ? <UsersTable /> : <UsersList />
              }
            />
          </Routes>
        </BrowserRouter>
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default App;
