import { validate } from "validate.js";

export const change = ({ value, name }, item, errors, constraints) => {
  const newItem = { ...item };
  newItem[name] = value;
  const newError = { ...errors };
  validate.validators.presence.message = "Questo campo non deve essere vuoto";
  const errorMessage = validate.single(value, constraints[name]);
  if (errorMessage) newError[name] = errorMessage[0];
  else newError[name] = "";
  return { changedItem: newItem, changedError: newError };
};

export const submit = (e, user, errors, constraints) => {
  e.preventDefault();
  const newError = { ...errors };
  validate.validators.presence.message = " non deve essere vuoto";
  const errorMessage = validate(user, constraints);
  if (errorMessage) {
    const keys = Object.keys(errorMessage);
    keys.map((k) => (newError[k] = errorMessage[k][0]));
    return newError;
  }
  return null;
};
