import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useReducer } from "react";
import DonationDonator from "./donations/donationDonator";
import DonationsImage from "./donations/donationsImage";
import { getCategories } from "../services/categoryService";
import { getPlants } from "../services/plantsService";
import reducer from "./donations/reducerDonation";
import {
  CHANGE_FILE,
  HANDLE_CHANGE,
  REMOVE_IMAGE,
  SET_CATEGORIES,
  SET_PLANTS,
  SET_PREVIEW,
  SET_STEP,
  STEP_BACK,
  STEP_ON,
  SET_ERRORS,
  IS_CHECKED,
} from "./donations/actionsDonation";
import { useNavigate } from "react-router-dom";
import { getConstraints } from "./utils/constraints";
import { submit } from "./utils/form";
import { insertDonationWithImage, insertDonationWithoutImage } from "../services/donationsService";

const steps = ["Dati della donazione", "Foto della donazione"];

const initialState = {
  activeStep: 0,
  checked: false,
  donators: [],
  categories: [],
  selectedFile: "",
  preview: "",
  plants: [],
  donation: {
    name: "",
    surname: "",
    description: "",
    donation_date: "",
    id_category: "",
    id_plants: "",
  },
  errors: {
    name: "",
    surname: "",
    description: "",
    donation_date: "",
    id_category: "",
    id_plants: "",
  },
};

const DonationsForm = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const {
    activeStep,
    categories,
    selectedFile,
    preview,
    plants,
    donation,
    errors,
    checked
  } = state;

  //Da separare le chiamate all'api con il file selezionato
  useEffect(() => {
    const getCategoriesData = async () => {
      let { data } = await getCategories();
      if (data) {
        const categories = data.data;
        dispatch({ type: SET_CATEGORIES, payload: categories });
      }
    };

    const getPlantsData = async () => {
      let { data } = await getPlants();
      if (data) {
        const plants = data.data;
        dispatch({ type: SET_PLANTS, payload: plants });
      }
    };

    if (categories.length === 0) getCategoriesData();
    if (plants.length === 0) getPlantsData();
    if (!selectedFile) {
      dispatch({ type: SET_PREVIEW, payload: undefined });
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    dispatch({ type: SET_PREVIEW, payload: objectUrl });

    //free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile, checked]);

  const handleSubmit = async (e) => {
    const constraints = getConstraints("donations");
    const result = submit(e, donation, errors, constraints);
    if (result) {
      dispatch({ type: SET_ERRORS, payload: { errors: result } });
      return;
    }
    let data;
    /*if (selectedFile){
      const formData = new FormData();
      formData.append('File', selectedFile);
      formData.append('data', donation);
    console.log(formData);
    data = await insertDonationWithImage({'formData': formData, 'data': donation});
    }
    else*/
      data = await insertDonationWithoutImage(donation);
    /*if (path.path === "users") data = await editUserById(path.id, user);
    else if (path.path === "new-user") data = await addUser(user);*/
    if (data.status) dispatch({ type: STEP_ON });
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1 }}>
          <AccountCircle />
        </Avatar>
        <Typography component="h1" variant="h5">
          Nuova donazione
        </Typography>{" "}
      </Box>
      <Box
        component="form"
        //onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <Box sx={{ width: "100%" }}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  color="inherit"
                  onClick={() => dispatch({ type: SET_STEP, payload: index })}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
        {activeStep === 0 ? (
          <DonationDonator
            onChange={({ target }) =>
              dispatch({ type: HANDLE_CHANGE, payload: { target: target } })
            }
            donation={donation}
            errors={errors}
            categories={categories}
            plants={plants}
            checked={checked}
            onChecked={() => dispatch({type: IS_CHECKED})}
            onClick={handleSubmit}
          />
        ) : (
          <Box sx={{ width: "100%" }}>
            <DonationsImage
              preview={preview}
              onChangeHandler={({ target }) =>
                dispatch({ type: CHANGE_FILE, payload: target })
              }
              onRemoveImage={() => dispatch({ type: REMOVE_IMAGE })}
            />
            <Grid container spacing={2} style={{ marginTop: "2%" }}>
              <Grid item xs={6}>
                {" "}
                <Button
                  margin="normal"
                  variant="contained"
                  color="error"
                  style={{ margin: "auto" }}
                  size="large"
                  onClick={() => dispatch({ type: STEP_BACK })}
                >
                  Indietro
                </Button>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Button
                  margin="normal"
                  variant="contained"
                  color="success"
                  style={{ margin: "auto" }}
                  size="large"
                >
                  Avanti
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default DonationsForm;
