import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box, TablePagination } from "@mui/material";
import CustomTable from "./utils/CustomTable";
import { select, SelectAllClick } from "./utils/selecting";
import { ImportXlxs } from "../services/excelService";
import { importPlantsWithIdDonation } from "../services/plantsService";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// const columns = [
//   "id",
//   "DESCRIPTION",
//   "DONATION_DATE",
//   "IMG_PATH",
//   "NAME",
//   "SURNAME",
//   "CATEGORY",
//   "PLANTING_DATE",
//   "TYPE",
// ];

const ImportDialog = ({ onOpen, getFile, onCloseImport }) => {
  const [columns, setColumns] = useState([])
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [selected, setSelected] = React.useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(columns[0]);

  useEffect(() => {
    const getData = () => {
      if (getFile) {
        handleXlsxParse(getFile);
      }
    };
    getData();
  }, [getFile]);

  const handleSelectAllClick = (event) => {
    const data = SelectAllClick(event, filteredData);
    setSelected(data);
  };

  const handleClick = (event, id) => {
    const newSelected = select(event, id, selected);
    setSelected(newSelected);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleImportClick = async() => {
    const {data} = await importPlantsWithIdDonation(filteredData);
    const importData = [...filteredData];  //Rimuove l'id donation dai dati che vanno in tabella
    importData.map(item => delete item.id_donation);
    if (data)
    console.log(importData);
      onCloseImport(importData);
  };
  const handleClose =() => {
    onCloseImport();
  }

  const handleXlsxParse = (e) => {
    const { files } = e.target;
    const reader = new FileReader();
    if (files) {
      reader.onload = (e) => {
        const json = ImportXlxs(e);
        setColumns(Object.keys(json[0]));
        setFilteredData(json);
      };
    }
    reader.readAsArrayBuffer(files[0]);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={onOpen}
        onClose={onCloseImport}
        TransitionComponent={Transition}
      >
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Data import
            </Typography>

            <Button autoFocus color="inherit" onClick={handleImportClick}>
              Import
            </Button>
          </Toolbar>
        </AppBar>
        <Box>
          <CustomTable
            onSelectAllClick={handleSelectAllClick}
            onSelectClick={handleClick}
            columns={columns}
            order={order}
            orderBy={orderBy}
            data={filteredData}
            selected={selected}
            onRequestSort={handleRequestSort}
            rowsPerPage={rowsPerPage}
            page={page}
          />
          <TablePagination
            rowsPerPageOptions={[4, 6, 10]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Dialog>
    </div>
  );
};
export default ImportDialog;
