import { insertDonationWithoutImage } from "../../services/donationsService";
import { getConstraints } from "../utils/constraints";
import { change, submit } from "../utils/form";
import {
  CHANGE_FILE,
  HANDLE_CHANGE,
  REMOVE_IMAGE,
  SET_CATEGORIES,
  SET_PLANTS,
  SET_PREVIEW,
  SET_STEP,
  STEP_ON,
  STEP_BACK,
  SET_ERRORS,
  IS_CHECKED,
} from "./actionsDonation";

const constraints = getConstraints("donations");
const reducer = (state, action) => {
  switch (action.type) {
    case SET_STEP:
      return { ...state, activeStep: action.payload };
    case STEP_ON:
      const activeStep = state.activeStep;
      return { ...state, activeStep: activeStep + 1 };
    case HANDLE_CHANGE:
      const { name, value } = action.payload.target;
      const { changedItem, changedError } = change(
        { value, name },
        state.donation,
        state.errors,
        constraints
      );
      return { ...state, errors: changedError, donation: changedItem };
    case SET_CATEGORIES:
      const categories = action.payload;
      return { ...state, categories: categories };
    case SET_PLANTS:
      const plants = action.payload;
      return { ...state, plants: plants };
    case SET_PREVIEW:
      const preview = action.payload;
      return { ...state, preview: preview };
    case CHANGE_FILE:
      const { files } = action.payload;
      return { ...state, selectedFile: files[0] };
    case REMOVE_IMAGE:
      return { ...state, selectedFile: undefined, preview: undefined };
    case STEP_BACK:
      const step = state.activeStep;
      return { ...state, activeStep: step - 1 };
    case SET_ERRORS:
      const { errors } = action.payload;
      return { ...state, errors: errors, activeStep: state.activeStep - 1 };
    case IS_CHECKED:
      const checked = state.checked;
      const donation = state.donation;
      if (!checked){
        delete donation.name;
        delete donation.surname;
        donation.id_donator = '';
      }
      return {...state, donation: donation, checked: !checked};
    default:
      return state;
  }
};

export default reducer;
