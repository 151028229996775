import {
  Box,
  Checkbox,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React from "react";
import { getComparator } from "./sorting";

const CustomTable = ({
  onSelectAllClick,
  columns,
  data,
  orderBy,
  order,
  selected,
  onSelectClick,
  onRequestSort,
  page,
  rowsPerPage,
}) => {
  //Verifica se la riga è selezionata, verificado tramite il nome
  const isSelected = (id) => {
    return selected.indexOf(id);
  };

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property.toLowerCase());
  };

  return !(data.length > 0) ? (
    <CircularProgress color="success" />
  ) : (
    <TableContainer component={Paper}>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                onChange={onSelectAllClick}
                inputProps={{
                  "aria-label": "select all data",
                }}
              />
            </TableCell>
            {columns.map((col) => {
              return (
                <TableCell
                  sortDirection={order === "asc" ? "asc" : "desc"}
                  key={col}
                >
                  <TableSortLabel
                    direction={orderBy === col.toLowerCase() ? order : "asc"}
                    onClick={createSortHandler(col)}
                    active={orderBy === col.toLowerCase() ? true : false}
                  >
                    <b>{col}</b>
                    {orderBy === col.toLowerCase() ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .sort(getComparator(order, orderBy))
            .map((el) => {

              return (
                <TableRow
                  key={el.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  selected={isSelected(el.id) === -1 ? false : true}
                  onClick={(event) => onSelectClick(event, el.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isSelected(el.id) === -1 ? false : true}
                      inputProps={{
                        "aria-label": "select all data",
                      }}
                    />
                  </TableCell>
                  {columns.map((prop) => {
                    return (
                      <TableCell
                        component="th"
                        scope="row"
                        key={prop.toLowerCase()}
                      >
                        {el[prop.toLowerCase()]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default CustomTable;
