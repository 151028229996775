import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteUserById, getUsers } from "../../services/usersService";
import CustomDialog from "../utils/CustomDialog";
import CustomList from "./customList";

const UsersList = () => {
  const navigate = useNavigate();
  const [usersData, setUsersData] = useState([]);
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 6;
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [checked, setChecked] = React.useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    const getUsersData = async () => {
      let { data } = await getUsers();
      if (data) {
        const users = data.map((item) => {
          return {
            primary: item.name + " " + item.surname,
            secondary: item.username,
            id: parseInt(item.id),
          };
        });
        //Determina il numero delle pagine
        if (users) {
          setUsersData(users);
          setNumberOfPages(Math.ceil(users.length / rowsPerPage));
        }
      }
    };
    getUsersData();
  }, []);

  const handleDeleteItem = async () => {
    const { data } = await deleteUserById();
    const newUsersData = usersData.filter(
      (user) => !checked.includes(user.id)
    );
    setUsersData(newUsersData);
    setChecked([]);
  };

  const handleClose = (status) => {
    if (status) {
      handleDeleteItem();
    }
    setOpenDialog(false);
  };

  return (
    <>
      <CustomList
        data={usersData}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onAddItem={() => navigate("/new-user")}
        numberOfPages={numberOfPages}
        checked={checked}
        onToggle={handleToggle}
        onEditItem={() => navigate(`/users/${checked[0]}`)}
        onDeleteItem={() => setOpenDialog(true)}
      />
      <CustomDialog
        title={"Attenzione"}
        text={"Confermi di eliminare l' utente?"}
        open={openDialog}
        onClose={handleClose}
      />
    </>
  );
};

export default UsersList;
