import * as xlsx from "xlsx";

// const importTest = (e) => {
//   const data = e.target.result;
//   const workbook = xlsx.read(data, { type: "array" });
//   const sheetName = workbook.SheetNames[0];
//   const worksheet = workbook.Sheets[sheetName];
//   const json = xlsx.utils.sheet_to_json(worksheet);
//   return json;
// };

//Passaggio nei parametri delle funzioni setData e setFilteredData ?
//async non serve
export function ImportXlxs(e) {
  const data = e.target.result;
  const workbook = xlsx.read(data, { type: "array" });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];
  const json = xlsx.utils.sheet_to_json(worksheet);
  const convertedJsonTable = json.map(row => lowercaseKeys(row));
  return convertedJsonTable;
}

const lowercaseKeys = obj =>
  Object.keys(obj).reduce((acc, key) => {
    acc[key.toLowerCase()] = obj[key];
    return acc;
  }, {});

// const handleFile = (e)=>{
//     let selectedFile = e.target.files[0];
//     if(selectedFile){
//       // console.log(selectedFile.type);
//       if(selectedFile&&fileType.includes(selectedFile.type)){
//         let reader = new FileReader();
//         reader.readAsArrayBuffer(selectedFile);
//         reader.onload=(e)=>{
//           setExcelFileError(null);
//           setExcelFile(e.target.result);
//         }
//       }
//       else{
//         setExcelFileError('Please select only excel file types');
//         setExcelFile(null);
//       }
//     }
//     else{
//       console.log('plz select your file');
//     }
//   }
