import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { change, submit } from "./utils/form";
import { addUser, editUserById, getUserById } from "../services/usersService";

//TODO handleChange e handleSubmit da mettere in un file a parte e crearle generiche
const UsersForm = () => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [path, setPath] = useState({
    id: "",
    path: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    name: "",
    surname: "",
  });
  const [user, setUser] = useState({
    name: "",
    surname: "",
    username: "",
    password: "",
  });

  useEffect(() => {
    const [path, id] = window.location.pathname.substring(1).split("/");
    setPath({ id: id, path: path });
    if (path === "users") {
      const getUserData = async () => {
        const { data } = await getUserById(id);
        console.log(data);
        data.password = "";
        setUser(data);
      };
      getUserData();
    }
  }, []);

  const constraints = {
    name: {
      presence: { allowEmpty: false },
    },
    surname: {
      presence: { allowEmpty: false },
    },
    username: {
      presence: { allowEmpty: false },
    },
    password: {
      presence: { allowEmpty: false },
    },
  };

  const handleChange = ({ target: { value, name } }) => {
    const { changedItem, changedError } = change(
      { value, name },
      user,
      errors,
      constraints
    );
    setErrors(changedError);
    setUser(changedItem);
    setDisabled(isDisabled(changedError));
  };

  const isDisabled = (error) => {
    if (!error) return;
    const { name, surname, username, password } = error;
    return name || surname || username || password ? true : false;
  };

  //TODO riscrivere la funzione in signin uguale a questa
  const handleSubmit = async (e) => {
    const result = submit(e, user, errors, constraints);
    setDisabled(isDisabled(result));
    if (result) {
      setErrors(result); //Quando si svilupperà submit() result avrà anche altri parametri
      return;
    }
    let data;
    if (path.path === "users") data = await editUserById(path.id, user);
    else if (path.path === "new-user") data = await addUser(user);
    if (data.status) navigate("/users");
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1 }}>
          <AccountCircle />
        </Avatar>
        <Typography component="h1" variant="h5">
          Nuovo utente
        </Typography>
        <Box
          component="form"
          //onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Name"
                name="name"
                value={user.name}
                onChange={handleChange}
                error={errors.name ? true : false}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="surname"
                value={user.surname}
                label="Surname"
                onChange={handleChange}
                error={errors.surname ? true : false}
                helperText={errors.surname}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Username"
                name="username"
                value={user.username}
                onChange={handleChange}
                error={errors.username ? true : false}
                helperText={errors.username}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                onChange={handleChange}
                value={user.password}
                error={errors.password ? true : false}
                helperText={errors.password}
              />
            </Grid>
            <div style={{ margin: "auto" }}>
              <Button
                type="submit"
                disabled={disabled}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSubmit}
              >
                {window.location.pathname.substring(1).split("/")[0] === "users"
                  ? "Modifica"
                  : "Aggiungi"}
              </Button>
            </div>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default UsersForm;
