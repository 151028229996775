import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deletePlantsById, getPlants } from "../../services/plantsService";
import CustomDialog from "../utils/CustomDialog";
import CustomList from "./customList";

const PlantsList = () => {
  const navigate = useNavigate();
  const [plantsData, setPlantsData] = useState([]);
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 6;
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [checked, setChecked] = React.useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    const getPlantsData = async () => {
      let { data } = await getPlants();
      if (data) {
        const plants = data.data.map((item) => {
          return {
            primary: item.id_tree,
            secondary: item.planting_date + " " + item.type,
            id: parseInt(item.id),
          };
        });

        //Determina il numero delle pagine
        if (plants) {
          setPlantsData(plants);
          setNumberOfPages(Math.ceil(plants.length / rowsPerPage));
        }
      }
    };
    getPlantsData();
  }, []);

  const handleDeleteItem = async () => {
    const { data } = await deletePlantsById();
    const newPlantsData = plantsData.filter(
      (user) => !checked.includes(user.id)
    );
    setPlantsData(newPlantsData);
    setChecked([]);
  };

  const handleClose = (status) => {
    if (status) {
      handleDeleteItem();
    }
    setOpenDialog(false);
  };

  return (
    <>
      <CustomList
        data={plantsData}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onAddItem={() => navigate("/new-tree")}
        numberOfPages={numberOfPages}
        checked={checked}
        onToggle={handleToggle}
        onEditItem={() => navigate(`/tree/${checked[0]}`)}
        onDeleteItem={() => setOpenDialog(true)}
      />
      <CustomDialog
        title={"Attenzione"}
        text={"Confermi di eliminare l' utente?"}
        open={openDialog}
        onClose={handleClose}
      />
    </>
  );
};

export default PlantsList;
