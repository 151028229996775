import { Box, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTable from "./utils/CustomTable";
import OptionsTable from "./utils/OptionsTable";
import { useNavigate } from "react-router-dom";
import { deleteUserById, getUsers } from "../services/usersService";
import CustomDialog from "./utils/CustomDialog";
import { filterSelect, select, SelectAllClick } from "./utils/selecting";
const columns = ["Name", "Surname", "Username", "Password"];

//TODO quando si prenderanno i dati dall'api si dovrà aggiungere filterdUsers come array
const UsersTable = () => {
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [usersData, setUsersData] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState({
    selected: "",
    text: "",
  });
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const getUsersData = async () => {
      let { data } = await getUsers();
      if (data) {
        setUsersData(data);
        setFilteredUsers(data);
      }
    };
    getUsersData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    const data = SelectAllClick(event, filteredUsers);
    setSelected(data);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  //Da fare funzione per la rimozione su php di 2 elementi
  const handleDeleteItem = async () => {
    const { data } = await deleteUserById();
    const newUsersData = usersData.filter(
      (user) => !selected.includes(user.id)
    );
    setFilteredUsers(newUsersData);
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const newSelected = select(event, id, selected);
    setSelected(newSelected);
  };

  const handleFilterSelect = (target) => {
    const { newFilter, newFilteredData } = filterSelect(target, filter, usersData);
    setFilter(newFilter);
    setFilteredUsers(newFilteredData);
  };

  const handleClose = (status) => {
    if (status) {
      handleDeleteItem();
    }
    setOpenDialog(false);
  };
  return (
    <div
      style={{
        width: "80%",
        textAlign: "center",
        margin: "auto",
        marginTop: "5%",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <OptionsTable
          columns={columns}
          filter={filter}
          onChangeSelect={handleFilterSelect}
          disabledButton={selected}
          deleteButton={() => setOpenDialog(true)}
          onAddItem={() => navigate("/new-user")}
          onEditItem={() => navigate(`/users/${selected[0]}`)}
        />
        <CustomTable
          onSelectAllClick={handleSelectAllClick}
          onSelectClick={handleClick}
          columns={columns}
          order={order}
          orderBy={orderBy}
          data={filteredUsers}
          selected={selected}
          onRequestSort={handleRequestSort}
          rowsPerPage={rowsPerPage}
          page={page}
        />
        <TablePagination
          rowsPerPageOptions={[2, 4, 10]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <CustomDialog
        title={"Attenzione"}
        text={"Confermi di eliminare l' utente?"}
        open={openDialog}
        onClose={handleClose}
      />
    </div>
  );
};

export default UsersTable;
