export const getConstraints = (type) => {
  if (type === "donations") {
    return {
      name: {
        presence: { allowEmpty: false },
      },
      surname: {
        presence: { allowEmpty: false },
      },
      donation_date: {
        presence: { allowEmpty: false },
      },
      id_category: {
        presence: { allowEmpty: false },
      },
    };
  }
};
